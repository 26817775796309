import {
    Button,
    Cascader,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Radio,
    Row,
    Select,
    Switch,
    TreeSelect,
    message
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import './Register.css';
import exios, { AxiosError, AxiosResponse } from 'axios';
import { Option } from 'antd/lib/mentions';
import { baseJsonUrl, baseUrl, ITokenPayload, httpHeaders, getToken, SESS_ID, getTokenPayload, getUserFullName, getUserContact } from '../App';
import dayjs from 'dayjs';
import { LineOutlined, MinusOutlined } from '@ant-design/icons';

interface userProfile {
    firstName: string,
    lastName: string,
    phoneNumber: string,
    email: string,
    dateOfBirth: string,
    idNumber: string,
    phoneNetwork: number,
    age: number,
    gender: number,
    province: number,
    homeLanguage: number,
    personalIncome: number,
    maritalStatus: number,
    children: number,
    highestEducation: number,
    occupation: number
};

interface config {
    "Age": [{
        Key: number,
        Name: string
    }],
    "Cache": [{
        Key: number,
        Name: string
    }],
    "Children": [{
        Key: number,
        Name: string
    }],
    "Education": [{
        Key: number,
        Name: string
    }],
    "Gender": [{
        Key: number,
        Name: string
    }],
    "Income": [{
        Key: number,
        Name: string
    }],
    "Language": [{
        Key: number,
        Name: string
    }],
    "MaritalStatus": [{
        Key: number,
        Name: string
    }],
    "Mobile": [{
        Key: number,
        Name: string
    }],
    "Occupation": [{
        Key: number,
        Name: string
    }],
    "Province": [{
        Key: number,
        Name: string
    }]
};

function Profile({ httpClient }: any) {
    const [config, setConfig] = useState<config | null>(null);
    const [userProfile, setUserProfile] = useState<userProfile | null>(null);
    const tokenRef = useRef<string>();
    const [form] = Form.useForm();

    const getProfileDetails = async (userId: string, token: string) => {
        await httpClient.get(`identity/account/profile-options/${userId}`,
            {
                baseURL: baseUrl,
                headers: {
                    ...httpHeaders,
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response: AxiosResponse) => {
                if (response.status == 200) {
                    setUserProfile(response.data.data);
                } else
                    message.error('error loading data');
            }).catch((err: AxiosError) => {
                message.error('http error has occured');
            });
    };

    const updateProfileDetails = async (userId: string, token: string, userProfile: userProfile) => {
        await httpClient.put('identity/account/UpdateProfile/',
            {
                ...userProfile
            },
            {
                baseURL: baseUrl,
                headers: {
                    ...httpHeaders,
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response: AxiosResponse) => {
                if (response.status == 200) {
                    getProfileDetails(userId, token);
                    message.success('profile changes were saved successfully');
                } else
                    message.error('profile could not be saved');
            }).catch((err: AxiosError) => {
                message.error('http error has occured');
            });
    };

    const getConfig = async () => {
        await httpClient.get('config.json', {
            baseURL: baseJsonUrl,
            httpHeaders: httpHeaders
        }).then((response: AxiosResponse) => {
            if (response.status == 200) {
                setConfig(response.data.Lookups);
            } else
                message.error('error loading data');
        }).catch((err: AxiosError) => {
            message.error('http error has occured');
        });
    };

    useEffect(() => {
        getConfig();

        const token = getToken(SESS_ID);
        if (token) {
            tokenRef.current = token;
            getProfileDetails(getTokenPayload(token).nameIdentifier, token);
        }
    }, []);

    useEffect(() => {
        if (userProfile && config) {
            const fullName = getUserFullName();
            const contact = getUserContact();
            
            form.setFieldValue('firstName', fullName?.firstName);
            form.setFieldValue('lastName', fullName?.lastName);
            form.setFieldValue('phoneNumber', contact?.phoneNumber);
            form.setFieldValue('email', contact?.email);
            form.setFieldValue('phoneNetwork', config.Mobile[userProfile.phoneNetwork].Name);
            form.setFieldValue('idNumber', userProfile.idNumber);
            form.setFieldValue('dateOfBirth', dayjs(userProfile.dateOfBirth, 'DD-MM-YYYY'));
            form.setFieldValue('age', config.Age[userProfile.age].Name);
            form.setFieldValue('gender', config.Gender[userProfile.gender].Name);
            form.setFieldValue('province', config.Province[userProfile.province].Name);
            form.setFieldValue('homeLanguage', config.Language[userProfile.homeLanguage].Name);
            form.setFieldValue('children', config.Children[userProfile.children].Name);
            form.setFieldValue('highestEducation', config.Education[userProfile.highestEducation].Name);
            form.setFieldValue('personalIncome', config.Income[userProfile.personalIncome].Name);
            form.setFieldValue('maritalStatus', config.MaritalStatus[userProfile.maritalStatus].Name);
            form.setFieldValue('occupation', config.Occupation[userProfile.occupation].Name);
        }
    }, [userProfile, config]);

    const getOptions = (optionsData: any) => {
        if (Array.isArray(optionsData)) {
            return optionsData
                .map((value: any, index: any) => {
                    return <Select.Option key={value.Key.toString()} value={value.Name}>{(value.Name == '' ? <LineOutlined /> : value.Name)}</Select.Option>
                })
        }
    };

    const getConfigKey = (lookup: { Key: number, Name: string }[] | undefined, formValue: string): number => {
        const key = lookup?.find(value => value.Name === formValue)?.Key;
        if (key)
            return key;
        else
            return 0;
    };

    const onFormFinish = (values: any) => {
        const updateData = {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            dateOfBirth: values.dateOfBirth.toDate(),
            idNumber: values.idNumber,
            phoneNetwork: getConfigKey(config?.Mobile, values.phoneNetwork),
            email: values.email,
            age: getConfigKey(config?.Age, values.age),
            gender: getConfigKey(config?.Gender, values.gender),
            province: getConfigKey(config?.Province, values.province),
            homeLanguage: getConfigKey(config?.Language, values.homeLanguage),
            personalIncome: getConfigKey(config?.Income, values.personalIncome),
            maritalStatus: getConfigKey(config?.MaritalStatus, values.maritalStatus),
            children: getConfigKey(config?.Children, values.children),
            highestEducation: getConfigKey(config?.Education, values.highestEducation),
            occupation: getConfigKey(config?.Occupation, values.occupation)
        };

        if (tokenRef.current)
            updateProfileDetails(getTokenPayload(tokenRef.current).nameIdentifier, tokenRef.current, updateData);
    };

    return (
        <>
            <Row align='middle' justify='center'>
                <Col span={22} offset={0}>
                    <Title className="sub-header" level={3} style={{ paddingBottom: '40px', paddingTop: '30px' }}>Update your Expand profile below</Title>
                </Col>
            </Row>
            <Row align='middle' justify='center'>
                <Col span={22}>
                    <Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 10 }}
                        layout="horizontal"
                        form={form}
                        onFinish={onFormFinish}
                    >
                        <Form.Item label="First Name" name='email' hidden>
                            <Input />
                        </Form.Item>

                        <Form.Item label="First Name" name='firstName'>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Last Name" name='lastName'>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Phone Number" name='phoneNumber'>
                            <Input disabled />
                        </Form.Item>

                        <Form.Item label="Mobile Network" name='phoneNetwork'>
                            <Select
                                placeholder="Select your mobile network...">
                                {getOptions(config?.Mobile)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="ID Number" name='idNumber'>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Date of Birth" name='dateOfBirth' >
                            <DatePicker format='DD-MM-YYYY' picker='date' />
                        </Form.Item>

                        <Form.Item label="Age Range" name='age'>
                            <Select

                                placeholder="Select your age range...">
                                {getOptions(config?.Age)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Gender" name='gender'>
                            <Select
                                placeholder="Select your age..."
                            >
                                {getOptions(config?.Gender)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Region/Province" name='province'>
                            <Select
                                placeholder="Select your region/Province..."
                            >
                                {getOptions(config?.Province)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Home Language" name='homeLanguage'>
                            <Select
                                placeholder="Select your home language..."
                            >
                                {getOptions(config?.Language)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Children" name='children'>
                            <Select
                                placeholder="Select number of chilren..."
                            >
                                {getOptions(config?.Children)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Education" name='highestEducation'>
                            <Select
                                placeholder="Select education level..."
                            >
                                {getOptions(config?.Education)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Income" name='personalIncome'>
                            <Select
                                placeholder="Select your income..."
                            >
                                {getOptions(config?.Income)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Marital Status" name='maritalStatus'>
                            <Select
                                placeholder="Select your marital status..."
                            >
                                {getOptions(config?.MaritalStatus)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Occupation" name='occupation' style={{ marginBottom: '40px' }}>
                            <Select

                                placeholder="Select your occupation..."
                            >
                                {getOptions(config?.Occupation)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="&nbsp;" colon={false}>
                            <Button type="primary" htmlType="submit" size='large'>
                                Save Profile
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
}

export default Profile;