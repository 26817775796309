import React, { StyleHTMLAttributes, useEffect, useRef, useState } from 'react';
import { Alert, Button, Checkbox, Col, Divider, Form, Input, InputRef, message, Row, Space, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Image } from 'antd';
import './Login.css';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { AxiosError, AxiosResponse } from 'axios';
import { baseUrl, encrypt, isDev, key, SESS_ID } from '../App';
import { EncryptStorage } from 'encrypt-storage';

function Login({ httpClient, isLoggedIn }: any) {
    const phoneNumber = useRef<any>();
    const password = useRef<any>();
    const [loginError, setLoginError] = useState<boolean>(false);
    const navigate = useNavigate();



    const onFinish = (values: any) => {
        loginUser(values.phoneNumber, values.password);
    };

    const onFinishFailed = (errorInfo: any) => {
        phoneNumber.current!.focus({
            cursor: 'all',
        });
    };

    const loginUser = async (phoneNumber: string, password: string) => {
        await httpClient.post('identity/token', { email: phoneNumber, password }, { baseURL: baseUrl })
            .then((response: AxiosResponse) => {
                if (response.status === 200) {
                    if (response.data.succeeded) {
                        
                        encrypt.setItem(SESS_ID, encrypt.encryptValue(response.data?.data?.token));

                        return navigate('/');
                    } else {
                        message.error('Invalid login');
                    }
                } else {
                    message.error(response.statusText);
                }
            }).catch((err: AxiosError) => {
                message.error('http error has occured');
                if (isDev)
                    message.error(err.message);
            });
    };

    if (isLoggedIn())
        return <Navigate to='/' replace />;

    const socialStyle = { color: '#fff', background: '#3a5593', border: 'none' };

    return (
        <>
            <div className='login-container'>
                <div style={{ width: '100%', maxWidth: '440px', padding: '15px' }}>
                    <div className='login-background' style={{ color: '#fff', minHeight: '80px' }}>
                        <Image width={120} src={'https://expand.co.za/wp-content/uploads/2021/12/Expand_Logo02-Orange-1.png'} alt='Expand Logo' style={{ color: '#fff' }} />
                    </div>
                    <header className="App-login login">
                        <Row>
                            <Col>
                                <Title level={3}>Login</Title>
                            </Col>
                        </Row>

                        <Form
                            style={{ marginTop: '10px' }}
                            name="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout='vertical'
                        >
                            <Form.Item
                                label="Phone Number"
                                name="phoneNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Phone numer is requred!',
                                    },
                                ]}
                            >
                                <Input size='middle' ref={phoneNumber} autoFocus />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Password is requred!',
                                    },
                                ]}
                            >
                                <Input.Password ref={password} size='middle' />
                            </Form.Item>

                            <Form.Item style={{ marginBottom: '0' }}>
                                <Space>
                                    <Link to="/reset-password">Forgot Password</Link>
                                </Space>
                            </Form.Item>

                            <Form.Item >
                                <Space>
                                    <Link to="/register">Don't have an account? Create an account</Link>
                                </Space>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" size='large' block>Log In</Button>
                            </Form.Item>
                        </Form>

                    </header>
                    <Divider style={{ marginBottom: '10px', marginTop: '10px' }} />
                    <div>
                        <Row justify='center'>
                            <Col>
                                <Space>
                                    <Tooltip title="Facebook">
                                        <Button type="primary" style={{ ...socialStyle, background: '#3a5593' }} target='_blank' href='https://www.facebook.com/ExpandSA' htmlType="button" size='large' shape="circle" icon={<FacebookOutlined />} />
                                    </Tooltip>
                                    <Tooltip title="Intagram">
                                        <Button type="ghost" style={{ ...socialStyle, background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)' }} target='_blank' href='https://www.facebook.com/ExpandSA' htmlType="button" size='large' shape="circle" icon={<InstagramOutlined />} />
                                    </Tooltip>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="bg-image"></div>
        </>
    );
}

export default Login;