import React, { useEffect, useRef, useState } from 'react';
import './Home.css';
import { Button, Carousel, Divider, message } from 'antd';
import moment, { DurationInputArg1, DurationInputArg2, Moment, unitOfTime } from 'moment';
import { flushSync } from 'react-dom';
import 'dayjs/locale/zh-cn';
import 'flipdown/dist/flipdown';
import { v4 as uuidv4 } from 'uuid';
import { AxiosInstance, AxiosResponse } from 'axios';
import { baseJsonUrl, baseUrl, forwardTime, getToken, getTokenPayload, httpHeaders, isAutoNext, isDev, ITokenPayload, SESS_ID } from '../App';
import { useNavigate } from 'react-router-dom';
import { SoundOutlined } from '@ant-design/icons';
import exp from 'constants';

interface IRectangle {
    Body: string,
    Logo: string,
    Id: number,
    Link: string
}

export interface INextEvent {
    currentDate: string,
    idCurrent: string,
    nextEvent: {
        id: string,
        startDate: string,
        musicUrl: string
    },
    events: [
        {
            game: string,
            ad: string,
            ab: number,
            gb: number
        }
    ]
}

interface IRemainingTime {
    days: number,
    hours: number,
    minutes: number,
    seconds: number,
}

interface ISlider {
    Body: string,
    Heading: string
    HeadingTwo: string
    HeaderImage: string
}

export interface IRegisterGame {
    ad: string,
    adDuration: number,
    currentDate: string,
    game: string,
    options: string,
    question: string
}

interface IRegWindow {
    duration: any,
    unitOfTime: string
}

function Home({ httpClient }: any) {
    const [rectagles, setRectangles] = useState(Array<IRectangle>);
    const [sliders, setSliders] = useState(Array<ISlider>);
    const [events, setEvents] = useState<INextEvent>();
    const [forwardTest, setForwardTest] = useState<{ isQuintine: boolean | undefined, isFannie: boolean | undefined }>();
    const registerGameDataRef = useRef<IRegisterGame>();
    const [eventRemainingTime, setEventRemainingTime] = useState<IRemainingTime>({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    const gameTime = useRef<Moment>();
    const currentTime = useRef<Moment>();
    const timerHandle = useRef<any>();

    const [isRegisterTime, setIsRegisterTime] = useState<boolean>(false);
    const [isRegistered, setIsRegistered] = useState<boolean>(false);
    const [userUnmuting, setUserUnmuting] = useState<boolean>(false);
    const [soundBtnDisanbled, setSoundBtnDisanbled] = useState<boolean>(false);
    const enterBtnRef = useRef<any>();
    const isRegisteredRef = useRef<boolean>();
    const audioPlayerRef = useRef<any>();
    const navigate = useNavigate();

    let windowDuration: DurationInputArg1 = 3;
    let windowUnitOfTime: DurationInputArg2 = 'minutes';

    if (isDev || forwardTime) {
        windowDuration = 15;
        windowUnitOfTime = 'seconds';
    }

    const getHomeData = () => {
        httpClient.get('home.json', {
            baseURL: baseJsonUrl,
            headers: httpHeaders
        }).then((response: AxiosResponse) => {
            if (response.status == 200) {
                setRectangles(response.data.Rectangles);
                setSliders(response.data.Sliders);
            } else
                message.error('Error loading data');

            return;
        }).catch((err: any) => {
            message.error('http error has occured');
        });
    };

    const getNextEvent = () => {
        (httpClient as AxiosInstance).get('v3/game/getevents', {
            baseURL: baseUrl,
            headers: httpHeaders
        }).then((response: AxiosResponse) => {
            if (response.status === 200) {
                setEvents(response.data);

                if (!gameTime?.current) {
                    gameTime.current = moment(response.data.nextEvent.startDate);
                }

                if (!currentTime?.current) {
                    currentTime.current = moment(response.data.currentDate);
                }

                getTimeRemaining();
            } else
                message.error('Error loading data');

            return;
        }).catch((err: AxiosResponse) => {
            message.error('http error has occured');
        }).finally(() => {
            clearInterval(timerHandle.current);
            if (currentTime.current && gameTime.current)
                remainingTimer();
        });
    };

    const registerEvent = (eventId: string, userId: string, isDevNavigateClick: boolean = false) => {
        const bodyData = {
            eventId, userId
        };

        httpClient.post('v3/game/register',
            bodyData,
            {
                baseURL: baseUrl,
                headers: {
                    ...httpHeaders,
                }
            }).then((response: AxiosResponse) => {
                if (response.status === 200) {
                    if (response.data.succeeded) {
                        isRegisteredRef.current = true;
                        setIsRegistered(true);
                        registerGameDataRef.current = response.data.data;

                        if (isDevNavigateClick)
                            navigate('/game/logo', { state: { gameSession: uuidv4(), registerData: response.data.data } });
                    } else
                        message.error('Error registering to play');
                } else
                    message.error('Error loading data');

                return;
            }).catch((err: any) => {
                message.error('http error has occured');
                if (err.response.data.messages[0])
                    message.error(err.response.data.messages[0], 3);
            });
    };

    const audioPlay = (e: any) => {
        if (audioPlayerRef.current.muted && navigator.platform.toLowerCase() != 'iphone') {
            audioPlayerRef.current.muted = false;
        } else {
            if (audioPlayerRef.current.muted)
                setUserUnmuting(true);
        }
    };

    const audioLoaded = (e: any) => {
    };

    const getTimeSpan = (date1: Moment, date2: Moment) => {
        var _date1 = date1.toDate();
        var _date2 = date2.toDate();

        var diff = _date2.getTime() - _date1.getTime();

        var days = Math.floor(diff / (1000 * 60 * 60 * 24));
        diff -= days * (1000 * 60 * 60 * 24);

        var hours = Math.floor(diff / (1000 * 60 * 60));
        diff -= hours * (1000 * 60 * 60);

        var mins = Math.floor(diff / (1000 * 60));
        diff -= mins * (1000 * 60);

        var seconds = Math.floor(diff / (1000));
        diff -= seconds * (1000);

        return {
            days: days,
            hours: hours,
            minutes: mins,
            seconds: seconds,
        } as IRemainingTime
    };

    useEffect(() => {
        getHomeData();
        getNextEvent();
    }, []);    

    useEffect(() => {
        if (isRegistered) {
            enterBtnRef.current.disabled = true;
            enterBtnRef.current.classList.add('disabled');
            enterBtnRef.current.textContent = 'Registered!';

            audioPlayerRef.current.src = `https://expand.co.za/app/music/${events?.nextEvent?.musicUrl}.mp3`;
            audioPlayerRef.current.play();
        };
    }, [isRegistered]);

    const getTimeRemaining = (): void => {
        const computedGameTime = moment(gameTime.current);
        const computedRegistrationTime = computedGameTime.clone().subtract(windowDuration, windowUnitOfTime);
        const computedCurrentTime = moment(currentTime.current);

        const registerTime = computedCurrentTime >= computedRegistrationTime && computedCurrentTime < computedGameTime;
        setIsRegisterTime(registerTime);

        if (!registerTime) {
            const timeSpan = getTimeSpan(computedCurrentTime, computedRegistrationTime);
            setEventRemainingTime(timeSpan);

            if (audioPlayerRef.current) audioPlayerRef.current.src = '';
        } else {
            const timeSpan = getTimeSpan(computedCurrentTime, computedGameTime);
            setEventRemainingTime(timeSpan);

            if (isTimeSpanZero(timeSpan)) {
                if (isRegisteredRef.current) {
                    isRegisteredRef.current = false;

                    if (!isDev || isAutoNext) {
                        navigate('/game/logo', { state: { gameSession: uuidv4(), registerData: registerGameDataRef.current } });
                    }
                    else
                        console.log('Enter game');
                }

                clearInterval(timerHandle.current);
                setIsRegistered(false);
                if (audioPlayerRef.current) audioPlayerRef.current.src = '';
                getNextEvent();
            };
        }

        currentTime.current = currentTime.current?.add(1, 'seconds');
    };

    const isTimeSpanZero = (timeSpan: IRemainingTime): boolean => {
        return timeSpan.days === 0 &&
            timeSpan.hours === 0 &&
            timeSpan.minutes === 0 &&
            timeSpan.seconds === 0;
    };

    const remainingTimer = () => {
        timerHandle.current = setInterval(() => {
            getTimeRemaining();
        }, 1000);
    };

    const registerGame = (e: any) => {
        const token = getToken(SESS_ID);
        if (token)
            registerEvent(events?.nextEvent.id as string, getTokenPayload(token).nameIdentifier);
    };

    const setTimer = () => {
        gameTime.current = moment().add(windowDuration, windowUnitOfTime).add(5, 'seconds');
    };

    useEffect(() => {
        if (!forwardTest?.isQuintine || !forwardTest?.isQuintine) {
            const token = getToken(SESS_ID);
            const payload = getTokenPayload(token as string);

            setForwardTest({
                ...forwardTest,
                isQuintine: payload.nameIdentifier == '7cf959df-0853-4504-8674-ab7c0d58e2c1',
                isFannie: payload.nameIdentifier == '1978d089-6255-45bf-89fb-b3a02e7f8ca8'
            });
        }

    }, []);

    return (
        <>
            <div className='slider-container'>
                <Carousel autoplay autoplaySpeed={10000}>
                    {sliders.map((s, i) => {
                        return (
                            <div className='slider' key={uuidv4()}>
                                <div className='slider-info'>
                                    <h3>{s.HeadingTwo}</h3>
                                    <h4>{s.Heading}</h4>
                                    <p>{s.Body}</p>
                                </div>
                                <div className='icon'>
                                    <img className='slider-icon' src={s.HeaderImage} alt={`${s.Heading} Logo`} />
                                </div>
                            </div>
                        )
                    })}
                </Carousel>
            </div>
            <div className='rectangle-container'>
                {rectagles.map((value: any, index: number) =>
                    <a key={index} href={value.Link} className='rectangle' target="_blank">
                        <div className='content'>
                            <h6 className='rectangle-body'>{value.Body}</h6>
                            <div className='rectangle-logo-container'>
                                <img className='rectangle-logo' src={value.Logo} alt={`${value.Body} Logo`} />
                            </div>
                        </div>
                    </a>
                )}
            </div>
            <div className='timer'>
                {eventRemainingTime ? <>
                    <div className={`timer-part${isRegisterTime ? ' music-time' : ''}`}>
                        <span>
                            {!isRegisterTime && eventRemainingTime.days > 0 ?
                                <>
                                    {`${eventRemainingTime?.days} days`} <span>&nbsp;</span>
                                </> : <></>}
                        </span>
                        {String(eventRemainingTime?.hours).padStart(2, '0')}
                        <span>:</span>
                        {String(eventRemainingTime?.minutes).padStart(2, '0')}
                        <span>:</span>
                        {String(eventRemainingTime?.seconds).padStart(2, '0')}
                    </div>
                </> : <></>}
            </div>
            {isRegisterTime ? <div className='timer'>
                <div className='timer-part-button'>
                    <button type='button' className='timer-part-button-btn register-open' ref={enterBtnRef} onClick={registerGame}>Enter</button>
                    {userUnmuting ? <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        <Button disabled={soundBtnDisanbled} type="primary" icon={<SoundOutlined />} onClick={(e) => {
                            audioPlayerRef.current.play();
                            audioPlayerRef.current.muted = false;
                            e.currentTarget.textContent = 'Sound is ON';
                            setSoundBtnDisanbled(true);
                        }}>Hear Sound</Button></div> : <></>}
                </div>
            </div> : <></>}
            {forwardTest?.isQuintine || forwardTest?.isFannie ? <div style={{ textAlign: 'center' }}>
                <button type='button' className='button' onClick={setTimer}>Forward Time [For Testing]</button>
            </div> : <></>}
            <audio ref={audioPlayerRef} onLoad={audioLoaded} onPlay={audioPlay} autoPlay muted style={{ display: 'none' }} />
            {isDev ? <button className='dev-next-btn' onClick={async () => {
                const token = getToken(SESS_ID);
                if (token)
                    await registerEvent(events?.nextEvent.id as string, getTokenPayload(token).nameIdentifier, true);
            }}>Go To Game</button> : <></>}
        </>
    )
}

export default Home;