import { DownOutlined, ExportOutlined, GiftFilled, HomeFilled, MenuFoldOutlined, MenuUnfoldOutlined, QuestionCircleFilled, RightOutlined, SmileFilled, TrophyFilled, UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Breadcrumb, Col, Collapse, CollapsePanelProps, Dropdown, Layout, Menu, MenuProps, Row, Space } from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import Item from 'antd/lib/list/Item';
import MenuItem from 'antd/lib/menu/MenuItem';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Link, Navigate, Outlet, redirect, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AnchorLink from 'antd/lib/anchor/AnchorLink';
import Sider from 'antd/lib/layout/Sider';
import Title from 'antd/lib/typography/Title';
import { Avatar, Image, Anchor } from 'antd';
import { stack as BurgerMenu } from 'react-burger-menu'
import './SiteLayout.css';
import { encrypt, getUserFullName, IUserFullName, SESS_ID } from './App';


function SiteLayout({ isLoggedIn }: any) {
    const selected = uuidv4();
    const navigate = useNavigate();
    const { Panel } = Collapse;

    const iconStyle = {
        fontSize: '1.2rem'
    };

    const items = [
        { label: <Link key={selected} to="/">Home</Link>, key: uuidv4(), icon: <HomeFilled style={iconStyle} /> },
        { label: <Link key={uuidv4()} to="/results">Results</Link>, key: uuidv4(), icon: <TrophyFilled style={iconStyle} /> },
        { label: <a href='https://expand.co.za/category/explore/' target={'_blank'}>Explore</a>, key: uuidv4(), icon: <GiftFilled style={iconStyle} /> },
        { label: <Link key={uuidv4()} to="/profile">Profile</Link>, key: uuidv4(), icon: <UserOutlined style={iconStyle} /> },
        {
            label: <>Info</>,
            key: uuidv4(),
            icon: <QuestionCircleFilled style={iconStyle} />,
            children: [
                { label: <a href='https://expand.co.za/how-to-play/' target={'_blank'}>How to Play</a>, key: uuidv4() },
                { label: <a href='https://expand.co.za/faqs/' target={'_blank'}>Frequently Asked Question</a>, key: uuidv4() },
                { label: <a href='https://expand.co.za/privacy-policy/' target={'_blank'}>Privacy Policy</a>, key: uuidv4() },
                { label: <a href='https://expand.co.za/competition-terms-and-conditions/' target={'_blank'}>Terms and Conditions</a>, key: uuidv4() }
            ]
        }
    ];

    const menu = (
        <Menu
            items={[
                { label: <a key={uuidv4()} style={{ float: 'right' }} onClick={() => logoutUser()} href='#'>Logout</a>, key: uuidv4(), icon: <ExportOutlined /> }
            ]}
        />
    );

    const logoutUser = () => {
        encrypt.removeItem(SESS_ID);

        return navigate('/login')
    };

    if (!isLoggedIn())
        return <Navigate to='/login' replace />;

    const logoStyle: CSSProperties = { float: 'left', height: '66px', background: 'rgba(255, 255, 255, 0.3)' };

    return (
        <Layout className="site-layout">
            <Header style={{ backgroundColor: '#fff', position: 'sticky', top: 0, zIndex: 1, width: '100%', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }} >
                <Row>
                    <Col span={16}>
                        <div className="menu-logo" style={logoStyle}>
                            <div className='hearder-stripe'></div>
                            <img src='https://expand.co.za/wp-content/uploads/2021/12/Expand_Logo02-Orange-1.png' alt='Expand logo' style={{ height: '100%', position: 'relative' }} />
                        </div>
                        <Menu
                            theme="light"
                            mode="horizontal"
                            selectedKeys={[selected]}
                            items={items}
                            className='top-menu'>
                        </Menu>
                        <BurgerMenu className='burger-menu' width='220px'>
                            <a id="home" key={uuidv4()} className="menu-item" href="/"><HomeFilled style={iconStyle} />&nbsp;&nbsp;Home</a>
                            <a id="results" key={uuidv4()} className="menu-item" href="/results"><TrophyFilled style={iconStyle} />&nbsp;&nbsp;Results</a>
                            <a id="explore" key={uuidv4()} className="menu-item" href="https://expand.co.za" target='_blank'><GiftFilled style={iconStyle} />&nbsp;&nbsp;Explore</a>
                            <a id="profile" key={uuidv4()} className="menu-item" href="/profile"><UserOutlined style={iconStyle} />&nbsp;&nbsp;Profile</a>
                            <Collapse ghost expandIconPosition='end' expandIcon={(props) => {
                                if (props.isActive)
                                    return <DownOutlined style={{ color: '#fe7400' }} />;
                                else
                                    return <RightOutlined style={{ color: '#fe7400' }} />;
                            }}>
                                <Panel className='submenu-header' style={{ marginLeft: '-16px' }} header={<a id="info" key={uuidv4()} className="menu-item submenu-panel"><QuestionCircleFilled style={iconStyle} />&nbsp;&nbsp;Info</a>} key={uuidv4()}>
                                    <div style={{ paddingLeft: '26px' }} className='submenu-body'>
                                        <a href='https://expand.co.za/how-to-play/' className="menu-item" target={'_blank'}>How to Play</a>
                                        <a href='https://expand.co.za/faqs/' target={'_blank'}>Frequently Asked Question</a>
                                        <a href='https://expand.co.za/privacy-policy/' target={'_blank'}>Privacy Policy</a>
                                        <a href='https://expand.co.za/competition-terms-and-conditions/' target={'_blank'}>Terms and Conditions</a>
                                    </div>
                                </Panel>
                            </Collapse>
                        </BurgerMenu>
                    </Col>
                    <Col span={8}>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <a onClick={e => e.preventDefault()} style={{ float: 'right' }}>
                                <Space size={'middle'} style={{ whiteSpace: 'nowrap' }}>
                                    <Avatar icon={<UserOutlined />} />
                                    <Title level={4} style={{ marginBottom: '0' }}>{`${getUserFullName()?.firstName}, ${getUserFullName()?.lastName}`}</Title>
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown>
                    </Col>
                </Row>
            </Header>
            <Content className='site-layout-content'>
                <Outlet />
            </Content>
            <Footer className="footer">
                <div>Expand 2022 | <a href="https://expand.co.za/competition-terms-and-conditions/">Competition Terms &amp; Conditions</a> | <a href="https://expand.co.za/terms-and-conditions/">Website Terms &amp; Conditions</a> | <a href="https://expand.co.za/privacy-policy/">Privacy Policy</a> </div>
            </Footer>
        </Layout>
    )
};

export default SiteLayout;