import React, { useEffect, useRef, useState } from 'react';
import './LogoGame.css';
import '../App.less';
import { Carousel, Col, Result, Row, Skeleton, Spin, message, BackTop, Space, Progress, Card, Statistic, Divider, Form, Input, Button } from 'antd';
import moment from 'moment';
import { flushSync } from 'react-dom';
import CurrencyFormat from 'react-currency-format';
import Title from 'antd/lib/typography/Title';
import { v4 as uuidv4 } from 'uuid';
import { baseUrl, getToken, getTokenPayload, httpHeaders, isAutoNext, isDev, isGameStateValid, ITokenPayload, preventUnloadEvent, SESS_ID } from '../App';
import { redirect, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ResultsLayout from './ResultsLayout';
import { AxiosError, AxiosResponse } from 'axios';
import { INextEvent, IRegisterGame } from './Home';
import { VideoJsPlayer } from 'video.js';
import ReactPlayer from 'react-player'
import { ArrowDownOutlined, ClockCircleOutlined } from '@ant-design/icons';
import FilePlayer from 'react-player/file';
import { OnProgressProps } from 'react-player/base';


function LogoGame({ httpClient }: any) {
    const { state } = useLocation();
    const navigate = useNavigate();

    if (!isDev && !isGameStateValid(state))
        navigate('/');

    //const [events, setEvents] = useState<INextEvent>();
    const [isGameOpen, setIsGameOpen] = useState<boolean>(false);
    const [videoUrl, setVideoUrl] = useState<string>('');
    const [progress, setProgress] = useState<number>();
    const [timer, setTimer] = useState<number>(30);
    const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
    const vidRef = useRef<any>(null);
    const answerInputRef = useRef<any>();
    const answerButtonRef = useRef<any>();
    const durationRef = useRef<number>(0);
    const currentTimeRef = useRef<number>(0);
    const elapsedRef = useRef<number>(0);

    const submitGameAnswer = async (answer: string, answerDuration: number, userId: string) => {
        await httpClient.post('v3/game',
            {
                answer: answer ? answer : 'NO ANSWER',
                answerDuration, userId
            },
            {
                baseURL: baseUrl,
                headers: httpHeaders
            }).then((response: AxiosResponse) => {
                if (response.status === 200) {
                    message.success('Answer submitted', 3);
                }
            }).catch((err: any) => {
                message.error('http error has occured');
                if (isDev)
                    message.error(err.message);
            });
    };

    useEffect(() => {
        setVideoUrl('');
        window.addEventListener('beforeunload', preventUnloadEvent);
    }, []);

    useEffect(() => {
        if (state?.registerData?.game) {
            setVideoUrl(`https://expand.co.za/app/game/${state.registerData.game}.mp4`);
            setIsGameOpen(true);
        } else
            setIsGameOpen(false);

        window.removeEventListener('beforeunload', preventUnloadEvent);
        window.addEventListener('beforeunload', preventUnloadEvent);
    }, []);

    let elaspedInterval: any;
    let interval: any;

    useEffect(() => {
        interval = setInterval(() => {
            if (isVideoPlaying()) {
                setTimer(timer - 1);
                currentTimeRef.current = + 1;
            }
        }, 1000);

        elaspedInterval = setInterval(() => {
            elapsedRef.current += 100;
        }, 100);

        return () => {
            clearInterval(interval);
            clearInterval(elaspedInterval);
        };
    }, [timer]);

    const logoVideoEnded = () => {
        clearInterval(elaspedInterval);
        clearInterval(interval);

        if (!isDev || isAutoNext)
            navigate('/game/ad/message', { state: state });
        else
            console.log('Video ended');
    };

    const logoVideoReady = async (reactPlayer: any) => {
        if (!isVideoPlaying()) {
            reactPlayer.getInternalPlayer().play();
        }
    };

    const isVideoPlaying = () => {
        return vidRef.current.player.isPlaying;
    };

    const logoVideoStart = () => {
        durationRef.current = Math.floor(vidRef.current.getDuration());
        setTimer(durationRef.current);

        answerInputRef.current!.focus({ cursor: 'all' });

        const player: HTMLVideoElement = vidRef.current.getInternalPlayer();
        if (player.muted && navigator.platform.toLowerCase() != 'iphone') {
            player.muted = false;
        }
    };

    const logoVideoPlaying = (state: OnProgressProps) => {
        const percentages = Math.floor((Math.floor(state.playedSeconds) / durationRef.current) * 100);
        setProgress(percentages);
    };

    const onFinish = async (values: any) => {
        clearInterval(elaspedInterval);
        const token = getToken(SESS_ID);
        if (token) {
            submitGameAnswer(values.logoAnswer, elapsedRef.current, getTokenPayload(token).nameIdentifier);
            answerButtonRef.current.textContent = 'Answer Submitted!';
            setComponentDisabled(true);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        answerInputRef.current!.focus({
            cursor: 'all',
        });
    };

    return (
        <>
            {isGameOpen ? <>
                <div className='logo-video-container'>
                    <Row justify='center' align='middle'>
                        <Col>
                            <div style={{ padding: '15px' }}>
                                <Statistic
                                    title={null}
                                    value={timer}
                                    precision={0}
                                    valueStyle={{ color: '#ff4d4f', fontSize: '2rem', fontWeight: '500' }}
                                    prefix={<ClockCircleOutlined style={{ marginRight: '10px', fontWeight: '100' }} />}
                                    suffix="&nbsp;seconds"
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className='progress-wrap' style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                        <Progress showInfo={false} strokeWidth={12} percent={progress} status="exception" />
                    </div>
                    <div className='logo-video' style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                        <ReactPlayer
                            playsinline
                            url={videoUrl}
                            playing
                            muted
                            width='100%'
                            ref={vidRef}
                            onEnded={logoVideoEnded}
                            onStart={logoVideoStart}
                            onProgress={logoVideoPlaying}
                            config={{
                                file: {
                                    attributes: {
                                        preload: 'none'
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className='answer-input'>
                        <Row justify='center' align='middle'>
                            <Col span={22}>
                                <Form
                                    style={{ width: '100%' }}
                                    name='logo-answer-form'
                                    initialValues={{ remember: true, }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    layout='vertical'
                                    disabled={componentDisabled}
                                >
                                    <Form.Item
                                        name="logoAnswer"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Your answer is requred!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Enter your answer' size='large' ref={answerInputRef} style={{ maxWidth: '470px', width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item
                                        name='answerButton'
                                    >
                                        <Button type="primary" htmlType="submit" ref={answerButtonRef} size='large'>Submit Answer</Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </> : <div className="loading-spiner"><Spin size='large' /></div>}
            {isDev ? <button className='dev-next-btn' onClick={() => navigate('/game/ad/message', { state: state })}>Next Page</button> : <></>}
        </>

    )
}

export default LogoGame;