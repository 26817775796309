import {
    Button,
    Cascader,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Radio,
    Row,
    Select,
    Switch,
    TreeSelect,
    message
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useReducer, useState } from 'react';
import './Register.css';
import exios, { Axios, AxiosResponse } from 'axios';
import { Option } from 'antd/lib/mentions';
import { baseUrl, httpHeaders, ITokenPayload } from '../App';
import dayjs from 'dayjs';
import { LineOutlined, MinusOutlined } from '@ant-design/icons';
import { Link, Navigate } from 'react-router-dom';

interface newUserDetails {
    firstName: string,
    lastName: string
    phoneNumber: string,
    password: string
}

function Register({ httpClient, isLoggedIn }: any) {
    const [form] = Form.useForm();

    const registerNewUser = async (details: newUserDetails) => {
        await httpClient.post('identity/user',
            { ...details },
            {
                baseURL: baseUrl,
                headers: {
                    ...httpHeaders
                }
            })
            .then((response: AxiosResponse) => {
                if (response.status == 200) {
                    if (response.data.succeeded) {
                        message.success('Registration was successful.', 4);

                        form.resetFields();
                    } else {
                        message.error(response.data.messages[0], 8);
                    }
                } else
                    message.error('Error registering user', 8);
            }).catch((err: any) => {
                message.error('http error has occured');
            });
    };

    const getConfigKey = (lookup: { Key: number, Name: string }[] | undefined, formValue: string): number => {
        const key = lookup?.find(value => value.Name === formValue)?.Key;
        if (key)
            return key;
        else
            return 0;
    };

    const onFormFinish = (values: any) => {
        const updateData = {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            password: values.password
        };

        registerNewUser(updateData);
    };


    if (isLoggedIn())
        return <Navigate to='/' replace />;


    return (
        <>
            <Row align='middle' justify='center'>
                <Col span={22} offset={0}>
                    <Title className="sub-header" level={3} style={{ paddingBottom: '0', paddingTop: '30px', marginBottom: '0' }}>Welcome To Expand!</Title>
                    <Title className="sub-header-2" level={5} style={{ paddingBottom: '40px', paddingTop: '0' }}>Register for your chance at winning</Title>
                </Col>
            </Row>
            <Row align='middle' justify='center'>
                <Col span={22}>
                    <Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 10 }}
                        layout="horizontal"
                        form={form}
                        onFinish={onFormFinish}
                    >
                        <Form.Item label="Phone Number" name='phoneNumber' required>
                            <Input />
                        </Form.Item>

                        <Form.Item label="First Name" name='firstName' required>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Last Name" name='lastName' required>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            required
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            required
                            name="confirmPassword"
                            label="Confirm Password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item label="&nbsp;" colon={false}>
                            <Link to="/login">Already have an account? Log in</Link>
                        </Form.Item>

                        <Form.Item label="&nbsp;" colon={false}>
                            <Button type="primary" htmlType="submit" size='large'>
                                Create Account
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
}

export default Register;