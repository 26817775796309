import {
    Button,
    Cascader,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Radio,
    Row,
    Select,
    Switch,
    TreeSelect,
    message
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useReducer, useState } from 'react';
import './ResetPassword.css';
import exios from 'axios';
import { Option } from 'antd/lib/mentions';
import { baseUrl, ITokenPayload } from '../App';
import dayjs from 'dayjs';
import { LineOutlined, MinusOutlined } from '@ant-design/icons';
import { Link, Navigate } from 'react-router-dom';

interface resetPasswordRequired {
    email: string,
    token: string,
    password: string,
    confirmPassword: string
};


function ResetPassword({ httpClient, isLoggedIn }: any) {
    const profileBase = `${baseUrl}`;
    const headers = { 'Access-Control-Allow-Origin': '*' };
    const [form] = Form.useForm();

    const resetPassword = async (phoneNumber: string) => {
        await httpClient.post('identity/user/forgot-password',
            { email: phoneNumber },
            {
                baseURL: profileBase,
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                }
            })
            .then((res: any) => {
                if (res.status == 200) {
                    message.success(res.data.messages);
                } else {
                    message.error('Password reset failed');
                }
            }).catch((err: any) => {
                message.error('http error has occured');
            });
    };

    const onFormFinish = (values: any) => {
        resetPassword(values.phoneNumber);
    };

    if (isLoggedIn())
        return <Navigate to='/' replace />;

    return (
        <>
            <Row align='middle' justify='center'>
                <Col span={22} offset={0}>
                    <Title className="sub-header" level={3} style={{ paddingBottom: '0', paddingTop: '30px', marginBottom: '0' }}>Reset Password</Title>
                    <Title className="sub-header-2" level={5} style={{ paddingBottom: '40px', paddingTop: '0' }}>Enter your phone number to reset your password</Title>
                </Col>
            </Row>
            <Row align='middle' justify='center'>
                <Col span={22}>
                    <Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 10 }}
                        layout="horizontal"
                        form={form}
                        onFinish={onFormFinish}
                    >
                        <Form.Item label="Phone Number" name='phoneNumber'>
                            <Input />
                        </Form.Item>

                        <Form.Item label="&nbsp;" colon={false}>
                            <Link to="/login">Go to login page to use the tempurary password that you got from the SMS.</Link>
                        </Form.Item>

                        <Form.Item label="&nbsp;" colon={false}>
                            <Button type="primary" htmlType="submit" size='large'>
                                Reset Password
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
}

export default ResetPassword;