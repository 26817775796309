import React, { useEffect, useRef, useState } from 'react';
import './AdGameQuestion.css';
import '../App.less'
import { Carousel, Col, Result, Row, Skeleton, Spin, message, BackTop, Space, Progress, Statistic, Divider, Form, Input, Button } from 'antd';
import moment from 'moment';
import { flushSync } from 'react-dom';
import CurrencyFormat from 'react-currency-format';
import Title from 'antd/lib/typography/Title';
import { v4 as uuidv4 } from 'uuid';
import { baseUrl, getToken, getTokenPayload, httpHeaders, isAutoNext, isDev, isGameStateValid, ITokenPayload, preventUnloadEvent, SESS_ID } from '../App';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ResultsLayout from './ResultsLayout';
import { AxiosResponse } from 'axios';
import { INextEvent, IRegisterGame } from './Home';
import { VideoJsPlayer } from 'video.js';
import ReactPlayer from 'react-player'
import { ArrowDownOutlined, ClockCircleOutlined } from '@ant-design/icons';


function AdGameQuestion({ httpClient }: any) {
    const { state } = useLocation();
    const navigate = useNavigate();

    if (!isDev && !isGameStateValid(state))
        navigate('/');

    //const [events, setEvents] = useState<INextEvent>();    
    const [isGameOpen, setIsGameOpen] = useState<boolean>(false);
    const [videoUrl, setVideoUrl] = useState<string>('');
    const [progress, setProgress] = useState<number>(0);
    const [timer, setTimer] = useState<number>(15);
    const vidRef = useRef<any>(null);
    const answerInputRef = useRef<any>(null);
    const answerButtonRef = useRef<any>();
    const elapsedRef = useRef<number>(0);
    const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
    const location = useLocation();
    const returnRedirect = navigate;


    const submitGameAnswer = async (answer: string, answerDuration: number, userId: string) => {
        await httpClient.post('v3/game/ad',
            {
                answer: answer ? answer : 'NO ANSWER',
                answerDuration, userId
            },
            {
                baseURL: baseUrl,
                headers: httpHeaders
            }).then((response: AxiosResponse) => {
            }).catch((err: any) => {
                message.error('http error has occured');
            });
    };

    useEffect(() => {
        window.removeEventListener('beforeunload', preventUnloadEvent);
        window.addEventListener('beforeunload', preventUnloadEvent);
    }, []);

    useEffect(() => {
        if (state?.registerData?.game) {
            setVideoUrl(`https://expand.co.za/app/game/${state.registerData.game}_ad.mp4`);
            setIsGameOpen(true);
        } else {
            setIsGameOpen(false);
        }
    }, []);

    let elaspedInterval: any;

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer === 0) {
                clearInterval(interval);
                clearInterval(elaspedInterval);

                if (!isDev || isAutoNext)
                    navigate('/results', { state: state });
                else
                    console.log('Time is up');
            } else {
                setTimer(timer - 1);
            }
        }, 1000);

        elaspedInterval = setInterval(() => {
            elapsedRef.current += 100;
        }, 100);

        return () => {
            clearInterval(interval);
            clearInterval(elaspedInterval);
        };
    }, [timer]);

    const onFinish = async (values: any) => {
        clearInterval(elaspedInterval);
        const token = getToken(SESS_ID);
        if (token) {
            submitGameAnswer(values.logoAnswer, elapsedRef.current, getTokenPayload(token).nameIdentifier);
            answerButtonRef.current.textContent = 'Answer Submitted!';
            setComponentDisabled(true);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        answerInputRef.current!.focus({
            cursor: 'all',
        });
    };

    return (
        <>
            {isGameOpen ? <>
                <div className='question-container'>
                    <div>
                        <Row justify='center' align='middle'>
                            <Col>
                                <div style={{ padding: '15px' }}>
                                    <Statistic
                                        title={null}
                                        value={timer}
                                        precision={0}
                                        valueStyle={{ color: '#ff4d4f', fontSize: '2rem', fontWeight: '500' }}
                                        prefix={<ClockCircleOutlined style={{ marginRight: '10px', fontWeight: '100' }} />}
                                        suffix="&nbsp;seconds"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row justify='center' align='middle'>
                            <Col>
                                <Title level={4} style={{ color: '#00000073' }}>Ad Question</Title>
                            </Col>
                        </Row>
                        <Row justify='center' align='middle'>
                            <Col>
                                <div className='ad-text'>
                                    <Title level={2}>{state.registerData.question}</Title>
                                </div>
                            </Col>
                        </Row>
                    </div>                    
                    <div className='answer-input'>
                        <Row justify='center' align='middle'>
                            <Col span={22}>
                                <Row justify='center' align='middle'>
                                    <Col span={22}>
                                        <Form
                                            style={{ marginTop: '10px', width: '100%' }}
                                            name='logo-answer-form'
                                            initialValues={{ remember: true, }}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                            layout='vertical'
                                            disabled={componentDisabled}
                                        >
                                            <Form.Item
                                                name="logoAnswer"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Your answer is requred!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder='Enter your answer' size='large' ref={answerInputRef} style={{ maxWidth: '470px', width: '100%' }} />
                                            </Form.Item>

                                            <Form.Item
                                                name='answerButton'
                                            >
                                                <Button type="primary" htmlType="submit" ref={answerButtonRef} size='large'>Submit Answer</Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </> : <div className="loading-spiner"><Spin size='large' /></div>}
            {isDev ? <button className='dev-next-btn' onClick={() => navigate('/results', { state: state })}>Next Page</button> : <></>}
        </>

    )
}

export default AdGameQuestion;