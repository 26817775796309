import React, { useEffect, useRef, useState } from 'react';
import './AdGame.css';
import { Carousel, Col, Result, Row, Skeleton, Spin, message, BackTop, Space, Progress, Button, Divider } from 'antd';
import moment from 'moment';
import { flushSync } from 'react-dom';
import CurrencyFormat from 'react-currency-format';
import Title from 'antd/lib/typography/Title';
import { v4 as uuidv4 } from 'uuid';
import { baseUrl, httpHeaders, isAutoNext, isDev, isGameStateValid, ITokenPayload, preventUnloadEvent } from '../App';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ResultsLayout from './ResultsLayout';
import { AxiosResponse } from 'axios';
import { INextEvent, IRegisterGame } from './Home';
import { VideoJsPlayer } from 'video.js';
import ReactPlayer from 'react-player'
import { OnProgressProps } from 'react-player/base';
import { SoundOutlined } from '@ant-design/icons';


function AdGame({ httpClient }: any) {
    const { state } = useLocation();
    const navigate = useNavigate();

    if (!isDev && !isGameStateValid(state))
        navigate('/');

    //const [events, setEvents] = useState<INextEvent>();
    const [isGameOpen, setIsGameOpen] = useState<boolean>(false);
    const [userUnmuting, setUserUnmuting] = useState<boolean>(false);
    const [soundBtnDisanbled, setSoundBtnDisanbled] = useState<boolean>(false);
    const [videoUrl, setVideoUrl] = useState<string>('');
    const [progress, setProgress] = useState<number>(0);
    const [timer, setTimer] = useState<number>(0);
    const vidRef = useRef<any>(null);
    const returnRedirect = navigate;
    const durationRef = useRef<number>(0);

    //const getNextEvent = async () => {
    //    await httpClient.get('v3/game/getevents',
    //        {
    //            baseURL: baseUrl,
    //            headers: httpHeaders
    //        }).then((response: AxiosResponse) => {
    //            if (response.status == 200) {
    //                setEvents(response.data);
    //            } else
    //                returnRedirect('/')
    //        }).catch((err: any) => {
    //            message.error('http error has occured');
    //        });
    //};

    const isVideoPlaying = () => {
        return vidRef.current.player.isPlaying;
    };

    useEffect(() => {
        if (state?.registerData?.ad) {
            setVideoUrl(`https://expand.co.za/app/game/${state.registerData.ad}_ad.mp4`);
            setIsGameOpen(true);
        } else {
            setIsGameOpen(false);
        }

        window.removeEventListener('beforeunload', preventUnloadEvent);
        window.addEventListener('beforeunload', preventUnloadEvent);
    }, []);

    const logoVideoEnded = () => {
        if (!isDev || isAutoNext)
            navigate('/game/ad/question', { state: state });
        else
            console.log('Video ended');
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer - 1)
        }, 1000);

        return () => clearInterval(interval);
    }, [timer]);

    const logoVideoPlaying = (state: OnProgressProps) => {
        const percentages = Math.floor((Math.floor(state.playedSeconds) / durationRef.current) * 100);
        setProgress(percentages);
    };

    const logoVideoStart = () => {
        durationRef.current = Math.floor(vidRef.current.getDuration());
        setTimer(durationRef.current);
        

        const player: HTMLVideoElement = vidRef.current.getInternalPlayer();
        if (player.muted && navigator.platform.toLowerCase() != 'iphone') {
            player.muted = false;
        } else {
            if (player.muted)
                setUserUnmuting(true);
        }
    };

    return (
        <>
            {isGameOpen ? <>
                <div className='ad-video-container'>
                    <div className='video'>
                        <div className='progress-wrap' style={{ paddingLeft: '0', paddingRight: '0' }}>
                            <Progress showInfo={false} strokeWidth={12} percent={progress} status="exception" />
                        </div>
                        <ReactPlayer
                            playsinline
                            url={videoUrl}
                            playing
                            muted
                            width='100%'
                            ref={vidRef}
                            onEnded={logoVideoEnded}
                            onStart={logoVideoStart}
                            onProgress={logoVideoPlaying}
                            config={{
                                file: {
                                    attributes: {
                                        preload: 'none'
                                    }
                                }
                            }}
                        />
                        {userUnmuting ? <div style={{ textAlign: 'center', marginTop: '10px' }}><Divider plain>Turn on the sound</Divider><Button disabled={soundBtnDisanbled} type="primary" icon={<SoundOutlined />} onClick={(e) => {
                            vidRef.current.getInternalPlayer().muted = false;
                            e.currentTarget.textContent = 'Sound is ON';
                            setSoundBtnDisanbled(true);
                        }}>Hear Sound</Button></div> : <></>}
                    </div>
                </div>
            </> : <div className="loading-spiner"><Spin size='large' /></div>}
            {isDev ? <button className='dev-next-btn' onClick={() => navigate('/game/ad/question', { state: state })}>Next Page</button> : <></>}
        </>

    )
}

export default AdGame;