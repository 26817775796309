import 'antd/dist/antd.css';
import Login from './account/Login';
import { Route, Routes } from 'react-router-dom';
import Home from './home/Home';
import SiteLayout from './SiteLayout';
import Register from './account/Register';
import Results from './home/Results';
import exios from 'axios';
import Profile from './account/Profile';
import './App.less';
import { EncryptStorage } from 'encrypt-storage';
import jwtDecode from 'jwt-decode';
import ResetPassword from './account/ResetPassword';
import LogoGame from './home/LogoGame';
import AdGame from './home/AdGame';
import AdGameQuestion from './home/AdGameQuestion';
import AdGameMessage from './home/AdGameMessage';
import process from 'process';
import moment from 'moment';
import ReactGA from "react-ga4";
import React, { useEffect } from 'react';

const TRACKING_ID = "G-1PHJE13QJZ";
ReactGA.initialize(TRACKING_ID);

//ReactGA.pageview(window.location.pathname + window.location.search);

ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });


export interface ITokenPayload {
    exp: number,
    role: string,
    emailAddress: string,
    mobilePhone: string,
    name: string,
    nameIdentifier: string,
    surname: string,
};

export interface IUserFullName {
    firstName: string | undefined,
    lastName: string | undefined
};

interface IUserContact {
    email: string;
    phoneNumber: string;
};

export const isDev = process.env.NODE_ENV === 'development';
export const isAutoNext = process.env.REACT_APP_AUTO_NEXT === 'true';
export const forwardTime = process.env.REACT_APP_FORWARD_TIME === "true";

const corsProxy = ''//'http://192.168.0.52:8080/';
const apiUrl = 'https://app.expand.co.za/api/';
const jsonUrl = 'https://expand.co.za/api/';

export const baseUrl = isDev ? `${corsProxy}${apiUrl}` : apiUrl;
export const baseJsonUrl = isDev ? `${corsProxy}${jsonUrl}` : jsonUrl;
export let httpHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
};

const httpClient = exios.create({ withCredentials: false });

export const SESS_ID: string = 'SESS_ID';
export const key = process.env.REACT_APP_SESSION_KEY as string;
export const encrypt = new EncryptStorage(key);

export const isGameStateValid = (state: any): boolean => {
    if (state) {
        const { gameSession } = state;
        if (!gameSession) {
            return false;
        } else
            return true;
    } else {
        return false;
    }
};

export const getTokenPayload = (token: string): ITokenPayload => {
    var tokenPayload = jwtDecode(token) as any;

    const payloadObj = {
        exp: tokenPayload.exp,
        role: tokenPayload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
        emailAddress: tokenPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
        mobilePhone: tokenPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone'],
        name: tokenPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
        nameIdentifier: tokenPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
        surname: tokenPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'],
    };

    return payloadObj;
};

export const getToken = (keyName: string): string | undefined => {
    try {
        const token = encrypt.decryptValue(encrypt.getItem(keyName) as string);
        try {
            const tokenDecoded = jwtDecode(token, { header: true });
            if (tokenDecoded) {
                return token;
            }
        } catch (e) {
            return undefined;
        }
    } catch (e) {
        return undefined;
    }
};

export const getUserFullName = (): IUserFullName | undefined => {
    const token = getToken(SESS_ID);
    if (token) {
        const payload = getTokenPayload(token);
        return {
            firstName: payload?.name,
            lastName: payload?.surname
        }
    } else
        return undefined
};

export const preventUnloadEvent = (event: Event) => {
    event.preventDefault();
    event.returnValue = false;
};

export const getUserContact = (): IUserContact | undefined => {
    const token = getToken(SESS_ID);
    if (token) {
        const payload = getTokenPayload(token);
        return {
            phoneNumber: payload?.mobilePhone,
            email: payload?.emailAddress
        }
    } else
        return undefined
};

export const isUserLoggedIn = (): boolean => {
    const token = getToken(SESS_ID);
    if (!token)
        return false;

    const payload = getTokenPayload(token as string);
    
    if (payload) {
        let expDate = payload.exp;
        if (!expDate)
            expDate = 0;

        const expDateObj = moment(expDate * 1000);
        const tokenExpired = expDateObj <= moment().subtract(20, 'minutes');
        return !tokenExpired;
    } else
        return false;

};


function App() {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    return (
        <Routes>
            <Route path='/login' element={<Login isLoggedIn={isUserLoggedIn} baseUrl={baseUrl} httpClient={httpClient} />} />
            <Route path='/register' element={<Register isLoggedIn={isUserLoggedIn} httpClient={httpClient} />} />
            <Route path='/reset-password' element={<ResetPassword isLoggedIn={isUserLoggedIn} httpClient={httpClient} />} />

            <Route path='/' element={<SiteLayout getUserFullName={getUserFullName} isLoggedIn={isUserLoggedIn} />}>
                <Route index element={<Home httpClient={httpClient} />} />
                <Route path='/profile' element={<Profile httpClient={httpClient} />} />
                <Route path='/results/*' element={<Results httpClient={httpClient} />} />

                <Route path='/game/logo' element={<LogoGame httpClient={httpClient} />} />
                <Route path='/game/ad/message' element={<AdGameMessage httpClient={httpClient} />} />
                <Route path='/game/ad' element={<AdGame httpClient={httpClient} userIIdentifier />} />
                <Route path='/game/ad/question' element={<AdGameQuestion httpClient={httpClient} />} />

                <Route path='*' element={<h1>Not Found</h1>} />
            </Route>
        </Routes>
    );
}

export default App;