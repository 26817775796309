import React, { useEffect, useState } from 'react';
import './Results.css';
import { Carousel, Col, Result, Row, Skeleton, Spin, message, BackTop, Card, Tooltip, Typography, Alert } from 'antd';
import moment from 'moment';
import { flushSync } from 'react-dom';
import CurrencyFormat from 'react-currency-format';
import Title from 'antd/lib/typography/Title';
import { v4 as uuidv4 } from 'uuid';
import { baseUrl, getToken, getTokenPayload, getUserFullName, httpHeaders, isDev, isGameStateValid, ITokenPayload, preventUnloadEvent, SESS_ID } from '../App';
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ResultsLayout from './ResultsLayout';
import { AxiosResponse } from 'axios';
import { BulbFilled, PhoneFilled, UnorderedListOutlined, UpOutlined, VerticalAlignTopOutlined } from '@ant-design/icons';
import { debug } from 'util';


interface IEventResults {
    duration2: number,
    name: string,
    position: number,
    randValue: number
};

interface IUserResults {
    duration2: number,
    name: string,
    position: number,
    randValue: number
};

interface IAdUserResults {
    containsData: boolean,
    duration: number | null,
    duration2: number,
    hooray: boolean
};

interface IPreviousEventResults {
    answer: string,
    answerAd: string,
    question: string,
};

function Results({ httpClient }: any) {
    const [results, setResults] = useState<Array<IEventResults> | null>(null);
    const [previousResults, setPreviousResults] = useState<IPreviousEventResults | null>(null);
    const [userResults, setUserResults] = useState<IUserResults | null>(null);
    const [adUserResults, setAdUserResults] = useState<IAdUserResults | null>(null);
    const [nextEvent, setNextEvent] = useState({ currentDate: null, idCurrent: null, nextEvent: { startDate: null, id: null } });
    const [topThree, setTopThree] = useState<Array<IEventResults> | null>(null);
    const [previousAnswerLogoUrl, setPreviousAnswerLogoUrl] = useState<string | undefined>();
    const [resultsNotAvalabale, setResultsNotAvalabale] = useState<boolean>();

    const { state } = useLocation();
    const navigate = useNavigate();
    const { Text } = Typography;


    if (isGameStateValid(state)) {
        setTimeout(() => {
            window.location.reload();

            setResultsNotAvalabale(false);
        }, 40000);

        //message.warning('Results will be available after 30 seconds or more, Browser will require refreshing.', 8);
    };

    useEffect(() => {
        window.removeEventListener('beforeunload', preventUnloadEvent);

    }, []);

    const getNextEvent = async () => {
        await httpClient.get('v3/game/getevents', { baseURL: baseUrl, headers: httpHeaders })
            .then((response: AxiosResponse) => {
                if (response.status === 200) {
                    setNextEvent(response.data);
                } else
                    message.error('error loading data');
            }).catch((err: any) => {
                message.error('http error has occured');
            });
    };

    const getResults = async (currentEventId: string | null) => {
        if (currentEventId) {
            await httpClient.get(`v3/game/results/${currentEventId}`, { baseURL: baseUrl, headers: httpHeaders })
                .then((response: AxiosResponse) => {
                    if (response.status === 200) {
                        setResults(response.data);
                    } else
                        message.error('error loading data');
                }).catch((err: any) => {
                    message.error('http error has occured');
                });
        }
    };

    const getUserResults = async (currentEventId: string | null, userId: string | null) => {
        if (currentEventId && userId) {
            await httpClient.get(`v3/game/result/${currentEventId}/${userId}`, { baseURL: baseUrl, headers: httpHeaders })
                .then((response: AxiosResponse) => {
                    if (response.status === 200) {
                        setUserResults(response.data[0]);
                    } else
                        message.error('error loading data');
                }).catch((err: any) => {
                    message.error('http error has occured');
                });
        }
    };

    const getAdUserResults = async (currentEventId: string | null, userId: string | null) => {
        if (currentEventId && userId) {
            await httpClient.get(`v3/game/adresult/${currentEventId}/${userId}`, { baseURL: baseUrl, headers: httpHeaders })
                .then((response: AxiosResponse) => {
                    if (response.status === 200) {
                        setAdUserResults(response.data.data);
                    } else {
                        message.error('Error loading data');
                    }
                }).catch((err: any) => {
                    message.error('http error has occured');
                });
        }
    };

    const getPreviousGameResults = async () => {
        await httpClient.get(`v3/game/previousevent`, { baseURL: baseUrl, headers: httpHeaders })
            .then((response: AxiosResponse) => {
                if (response.status === 200) {
                    if (response.data.succeeded) {
                        setPreviousResults(response.data.data);
                        if (response.data.data?.answer)
                            setPreviousAnswerLogoUrl(`https://expand.co.za/app/logo/${response.data.data?.answer}.png`);
                    } else {
                        message.error('Could not load data');
                    }
                } else {
                    message.error('Error getting data');
                }
            }).catch((err: any) => {
                console.log(err);
            });
    };

    const resultsClick = () => {
        getResults(nextEvent?.idCurrent);
        const token = getToken(SESS_ID);
        if (token)
            getUserResults(nextEvent?.idCurrent, getTokenPayload(token).nameIdentifier);
    };

    const airtimeClick = () => {
        const token = getToken(SESS_ID);
        if (token)
            getAdUserResults(nextEvent?.idCurrent, getTokenPayload(token).nameIdentifier);
    };

    const answerClick = () => {
        getPreviousGameResults();
    };

    useEffect(() => {
        getNextEvent();
    }, []);

    useEffect(() => {
        resultsClick();
        airtimeClick();
        answerClick();
    }, [nextEvent]);

    useEffect(() => {
        const topThree = results?.sort((a, b) => {
            if (a.position < b.position)
                return -1;
            else if (a.position > b.position)
                return 1;
            else
                return 0;
        }).splice(0, 3);

        if (topThree)
            setTopThree(topThree);
    }, [results]);

    const skeleton = (className: string) => {
        return (
            <>
                <Skeleton className={className} active />
                <Skeleton className={className} active />
                <Skeleton className={className} active />
            </>)
    };

    return (
        <>
            <div className='results-nav'>
                <div className='results-nav-link'><Link onClick={resultsClick} to='.'><UnorderedListOutlined className='sider-icon' /><span className='sider-label'>Results</span></Link></div>
                <div className='results-nav-link'><Link onClick={airtimeClick} to='airtime'><PhoneFilled className='sider-icon' /><span className='sider-label'>Airtime</span></Link></div>
                <div className='results-nav-link'><Link onClick={answerClick} to='answer'><BulbFilled className='sider-icon' /><span className='sider-label'>Answer</span></Link></div>
            </div>
            <section className='results-content'>
                <Routes>
                    <Route index element={
                        <>
                            <Title className="sub-header" level={3} style={{ paddingBottom: '40px', paddingTop: '30px' }}>Results</Title>
                            <div className='results-container'>
                                {results && results.length > 0 ?
                                    <>
                                        <div className='results-rectangle you c-subscribe-box' key={uuidv4()}>
                                            <div className="rainbow"><span></span><span></span></div>
                                            <div className="c-subscribe-box__wrapper">
                                                <div className='info'>
                                                    <h5 className='position'>{userResults?.position === 0 ? 'You' : `You are in position ${userResults?.position}`}</h5>
                                                    <h5 className='name'>{userResults?.randValue === 0 ? `${getUserFullName()?.firstName}. ${getUserFullName()?.lastName}, try again next time` : `${getUserFullName()?.firstName}. ${getUserFullName()?.lastName}, congredulation`}</h5>
                                                </div>
                                                <div className='amount'>
                                                    <span><CurrencyFormat value={userResults?.randValue} displayType={'text'} thousandSeparator={true} prefix={'R'} /></span>
                                                </div>
                                            </div>
                                        </div>
                                        {topThree?.map(r => {
                                            return (
                                                <div className='results-rectangle' key={uuidv4()}>
                                                    <div className='info'>
                                                        <h5 className='position'>{(r.position === 1 ? 'Winner!' : (r.position == 2 ? `${r.position}nd` : `${r.position}rd`))}</h5>
                                                        <h5 className='name'>{r.name}</h5>
                                                    </div>
                                                    <div className='amount'>
                                                        <span><CurrencyFormat value={r.randValue} displayType={'text'} thousandSeparator={true} prefix={'R'} /></span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className='results-list'>
                                            {results?.map(r => {
                                                return (
                                                    <div className='results-list-item' key={uuidv4()}>
                                                        <div className='results-list-info'>
                                                            <span className='position'>{r.position}</span>
                                                            <span className='name'>{r.name}</span>
                                                        </div>
                                                        <span className='amount'>
                                                            <span><CurrencyFormat value={r.randValue} displayType={'text'} thousandSeparator={true} prefix={'R'} /></span>
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                    :
                                    <Alert
                                        message="Thanks for playing"
                                        description={
                                            <>
                                                <div>Results will be up shortly</div>
                                                <div>Refresh your screen after 30 seconds</div>
                                            </>
                                        }
                                        type="info"
                                    />
                                }
                            </div>
                            <BackTop style={{ right: '32px', bottom: '80px' }}>
                                <Tooltip placement="top" title='Go To The Top'>
                                    <div className='back-top'>
                                        <VerticalAlignTopOutlined />
                                    </div>
                                </Tooltip>
                            </BackTop>
                        </>
                    } />
                    <Route path='airtime' element={
                        <>
                            <Title className="sub-header" level={3} style={{ paddingBottom: '40px', paddingTop: '30px' }}>Airtime</Title>
                            <div className='airtime-container'>
                                {adUserResults ?
                                    (<>
                                        <Title level={2} style={{ marginBottom: '5px', fontSize: '1.8rem' }}>
                                            {adUserResults?.hooray ?
                                                'Hooray'
                                                : 'Eish!, sorry try again next time'}
                                        </Title>
                                        <div className='airtime-card' style={{ textAlign: 'center' }}>
                                            <Title level={2}>
                                                {adUserResults?.hooray ?
                                                    <>
                                                        <div style={{ fontSize: '1.6rem' }}>You've Won</div>
                                                        <div style={{ fontSize: '4rem', color: '#d95a00', fontWeight: 600 }}>R10</div>
                                                        <div style={{ fontSize: '1.6rem' }}>Airtime</div>

                                                    </>
                                                    : <Title level={3}>Continue playin <span style={{ color: 'red' }}>expand at 8pm</span> weeknights to stand a chance to win.</Title>}
                                            </Title>
                                        </div>
                                    </>)
                                    : <div className="loading-spiner"><Spin size='large' /></div>}
                            </div>
                        </>
                    } />
                    <Route path='answer' element={
                        <>
                            <Title className="sub-header" level={3} style={{ paddingBottom: '40px', paddingTop: '30px' }}>Answer</Title>
                            {previousAnswerLogoUrl ? <img className='answer-logo' src={previousAnswerLogoUrl} alt={`Reveal Logo`} width='260px' /> : <></>}
                            <div className='answer-container'>
                                {previousResults ?
                                    (<Card title={<Title level={2}>Question: {previousResults?.question}</Title>}
                                        hoverable
                                        style={{ textAlign: 'center' }}>
                                        <Title level={3}>Answer: {previousResults?.answerAd}</Title>
                                    </Card>) : <div className="loading-spiner"><Spin size='large' /></div>}
                            </div>
                        </>
                    } />
                </Routes>
            </section>
        </>
    )
}

export default Results;