import React, { useEffect, useRef, useState } from 'react';
import './AdGameMessage.css';
import '../App.less';
import { Carousel, Col, Result, Row, Skeleton, Spin, message, BackTop, Space, Progress } from 'antd';
import moment from 'moment';
import { flushSync } from 'react-dom';
import CurrencyFormat from 'react-currency-format';
import Title from 'antd/lib/typography/Title';
import { v4 as uuidv4 } from 'uuid';
import { baseJsonUrl, httpHeaders, isAutoNext, isDev, isGameStateValid, ITokenPayload, preventUnloadEvent } from '../App';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ResultsLayout from './ResultsLayout';
import { AxiosResponse } from 'axios';
import { INextEvent, IRegisterGame } from './Home';
import { VideoJsPlayer } from 'video.js';
import ReactPlayer from 'react-player'


interface Labels {
    NoGame: string,
    MessageWonAirtime: string,
    MessageWonCash: string,
    NavigatedAway: string,
    AdMessage: string,
}

function AdGameMessage({ httpClient }: any) {
    const { state } = useLocation();
    const navigate = useNavigate();

    if (!isDev && !isGameStateValid(state))
        navigate('/');

    const [labels, setLabels] = useState<Labels | null>(null);
    const [progress, setProgress] = useState<number>(0);
    const currentSecondsRef = useRef<number>(0);

    let messageInterval: any;
    const totalSeconds: number = 5;

    const getLabels = async () => {
        await httpClient.get('config.json',
            {
                baseURL: baseJsonUrl,
                headers: httpHeaders
            }).then((response: AxiosResponse) => {
                if (response.status == 200) {
                    setLabels(response.data.Labels);
                } else {
                    message.error('http error has occured');
                }
            }).catch((err: any) => {
                message.error('http error has occured');
            });
    };

    useEffect(() => {
        getLabels();

        window.removeEventListener('beforeunload', preventUnloadEvent);
        window.addEventListener('beforeunload', preventUnloadEvent);
    }, []);

    useEffect(() => {
        messageInterval = setInterval(() => {
            currentSecondsRef.current += 1;
            const percentages = (currentSecondsRef.current / totalSeconds) * 100;

            setProgress(Math.floor(percentages));

            if (currentSecondsRef.current == totalSeconds) {                
                if (!isDev || isAutoNext)
                    navigate('/game/ad', { state: state });
                else
                    console.log('Video Ended');
            }
        }, 1000);

        return () => clearInterval(messageInterval);
    }, [labels]);

    return (
        <>
            <div className='ad-video-container'>
                <div className='progress-wrap'>
                    <Progress showInfo={false} strokeWidth={12} percent={progress} status="exception" />
                </div>
                <div className='ad-text'>
                    <Title level={2}>{labels?.AdMessage}</Title>
                </div>
            </div>
            {isDev ? <button className='dev-next-btn' onClick={() => navigate('/game/ad', { state: state })}>Next Page</button> : <></>}
        </>
    )
}

export default AdGameMessage;